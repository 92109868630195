<template>
  <div class="page inPage DropdownMenuPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">DropdownMenu</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="基础用法">
        <lee-dropdown-menu>
          <lee-dropdown-item v-model="value1" :options="option1"/>
          <lee-dropdown-item v-model="value2" :options="option2"/>
        </lee-dropdown-menu>
      </lee-cell-group>
      <lee-cell-group title="自定义选中态颜色">
        <lee-dropdown-menu active-color="#f00">
          <lee-dropdown-item v-model="value1" :options="option1"/>
          <lee-dropdown-item v-model="value2" :options="option2"/>
        </lee-dropdown-menu>
      </lee-cell-group>
      <lee-cell-group title="向上展开">
        <lee-dropdown-menu direction="up">
          <lee-dropdown-item v-model="value1" :options="option1"/>
          <lee-dropdown-item v-model="value2" :options="option2"/>
        </lee-dropdown-menu>
      </lee-cell-group>
      <lee-cell-group title="事件监听">
        <lee-dropdown-menu>
          <lee-dropdown-item v-model="value1" :options="option1" @onOpen="onOpen" @onClose="onClose"
                             @onChange="onChange"/>
        </lee-dropdown-menu>
      </lee-cell-group>
      <lee-cell-group title="DropdownItem 方法">
        <lee-button @click="dk">打开第二项</lee-button>
        <lee-button @click="gb">关闭第二项</lee-button>
        <lee-dropdown-menu>
          <lee-dropdown-item v-model="value1" :options="option1"/>
          <lee-dropdown-item v-model="value2" :options="option2" ref="my"/>
        </lee-dropdown-menu>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import {showToast} from "@/leeplus";
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}
//
//const index = ref('');
const value1 = ref(0);
const value2 = ref('a');
const option1 = [
    {text: '全部商品', value: 0},
    {text: '新款商品', value: 1},
    {text: '活动商品', value: 2},
];
const option2 = [
    {text: '默认排序', value: 'a'},
    {text: '好评排序', value: 'b'},
    {text: '销量排序', value: 'c'},
];


const onOpen = () => {
    showToast('打开')
}
const onClose = () => {
    showToast('关闭')
}
const onChange = (i) => {
    showToast('选择' + i)
}
const my = ref(null)
const dk=()=>{
    my.value.toggle(true)
}
const gb=()=>{
    my.value.toggle(false)
}

</script>
<style>


</style>
